<template>
  <div>
    <h1>Customer Detail {{ $route.params.customerId }}</h1>

    <ul>
      <li>Customer purchase history</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CustomerDetail'
}
</script>
